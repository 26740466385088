import './ModalViewPicture.css'

export default function ModalViewPicture(props : {picture?:string, close:()=>void})
{
    let { picture, close } = props

    if(picture)
    {
        return <div className='modal' onClick={e => close()}>
            <img className='picture-visualize' src={picture} alt={picture.split('/')[0]}/>
        </div>
    }
    else
    {
        return <></>
    }
}