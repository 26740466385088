export const picturesListing = [
    "cubes.png",
    "dracula.png",
    "hexameta.png",
    "img_parallax1.png",
    "img_parallax2.png",
    "img_parallax3.png",
    "img_parallax4.png",
    "island.png",
    "lightchipset.png",
    "old.img_parallax1.png",
    "old2.img_parallax1.png",
    "old4.img_parallax1.png",
    "petrichor.png"   
]