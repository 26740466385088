import { useState } from "react";
import { picturesListing } from "./picturesListing";

export interface Tile
{
  original : string
  preview : string
}

function fillDatas(quantity:number)
{ 
  let tiles = [] as Tile[]
  let pics = [] as string[]

  while(tiles.length < quantity)
  {
    if(pics.length === 0)
    {
      pics = picturesListing.concat()
    }

    let pic = pics.splice(Math.floor(Math.random() * pics.length), 1)[0]
        
    tiles.push({ original : 'galery/originals/' + pic, preview : 'galery/previews/' + pic })
  }
  return tiles
}

export default function usePicturesGalery(quantityPictures:number)
{
    const [tiles] = useState(fillDatas(quantityPictures))
    
    return {
        tiles : tiles
    }
}