import { useEffect, useState } from 'react';
import './App.css';
import ModalViewPicture from './ModalViewPicture';
import usePicturesGalery from './hooks/usePicturesGalery';

function App() {
    
  const {tiles} = usePicturesGalery(99);
  const [pictureSelected , setPictureSelected] = useState('')

  useEffect(()=>
  {
    tiles.forEach((tile) => fetch(tile.original));
  },
    [])

  return (
      <div>
        <ul id="hexGrid">
        {
          tiles.map((elt, key) => 
              <li key={key} className="hex" onClick={e => setPictureSelected(elt.original)}>
                <div className="hexIn">
                  <a className="hexLink" href="#">
                    <img src={elt.preview} alt={elt.preview.split('/')[0]} style={{filter: 'grayscale()'}}/>
                  </a>
                </div>
              </li>)
        }
        </ul>
        <div className='container-banner-title'>
          <h1 className='banner-title'> THE WASMANS GALLERY © </h1>
        </div>
        <ModalViewPicture picture={pictureSelected} close={() => setPictureSelected('')}/>
    </div>
  );
}

export default App;
